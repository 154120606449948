import React from "react"
import Collection from "../../components/Collection"
import { graphql } from "gatsby"

const fineArt = ({ data, location }) => {
    const collectionDescriptionFromState = location.state?.collectionDescription;
    const collectionDescriptionFromQuery = data.collectionData?.nodes[0]?.collection_description?.collection_description;
    const collectionDescription = collectionDescriptionFromState || collectionDescriptionFromQuery || "";
    // 4. Recupera meta_title e meta_description dalla query GraphQL
    const metaTitle = data.collectionData?.nodes[0]?.meta_title_collection || "Fine Art & Sculptures";
    const metaDescription = data.collectionData?.nodes[0]?.meta_description_collection?.meta_description_collection || "Fine Art & Sculptures";

    return (
        <div>
            <Collection
                pagination="fine-art-&-sculptures"
                data={data}
                title="Fine Art & Sculptures"
                keywords={data.items.nodes[0].categoria}
                collectionDescription={collectionDescription}  // Passa collectionDescription
                meta_title={metaTitle}
                meta_description={metaDescription}
            />
        </div>
    )
}

export const query = graphql`
  {
    items: allContentfulOggetto(
      filter: { categoria: { eq: "fine-art-&-sculptures" } }
      sort: { order: [ASC, ASC], fields: [sottocategoria, ordineDisposizione] }
    ) {
      nodes {
        titolo
        designer
        sold
        ordineDisposizione
        fotoAnteprima {
          fluid {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        slug: indirizzo
        sottocategoria
      }
    }
    collectionData: allContentfulCollection(
      filter: { title: { eq: "FINE ART & SCULPTURES" } }
    ) {
      nodes {
        collection_description {
          collection_description
        }
        meta_title_collection 
        meta_description_collection {
            meta_description_collection
        }        
      }
    }
  }
`

export default fineArt